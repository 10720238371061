import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./nav";
import { MantineProvider } from "@mantine/core";

const App = () => {
  return (
    <div className="body">
      <React.StrictMode>
        <MantineProvider
          theme={{
            colors: {
              purpleish: [
                "#FDFDFE",
                "#DCD6F7",
                "#BFB6E9",
                "#A89DD8",
                "#958AC6",
                "#857BB4",
                "#776EA2",
                "#6D6590",
              ],
            },
          }}
        >
          <RouterProvider router={router} />
        </MantineProvider>
      </React.StrictMode>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

reportWebVitals();
