import { Image, Space } from "@mantine/core";
import { ModuleCard } from "../../components/module-card";
import { ModuleItem } from "../../components/module-item";
import { MyText } from "../../components/text";

export const Friday = () => {
  return (
    <ModuleCard date={"Friday 8. September"}>
      <ModuleItem
        title={"18:10 Tour of Nuremberg's rock-cut cellars"}
        mapLink={
          "https://www.google.com/maps/place/F%C3%B6rderverein+N%C3%BCrnberger+Felseng%C3%A4nge+e.V./@49.4538129,11.0707134,15.24z/data=!4m6!3m5!1s0x479f57b1d4304567:0x3b88e7b8748cdcf7!8m2!3d49.4569762!4d11.0748632!16s%2Fg%2F121v8718?entry=ttu"
        }
        websiteLink={"https://www.historische-felsengaenge.de/en/information-about-us"}
      >
        <MyText>
          Did you know about these cellars? <br />
        </MyText>
        <MyText italic={true}>Probably not.</MyText>
        <Space h={10} />
        <MyText>
          Do you want to learn more about them? <br />
        </MyText>
        <MyText italic>Doesn't really matter, you are going to either way.</MyText>
        <Space h={10} />
        <MyText>So, let's give you some context. This is what they look like:</MyText>
        <Image src={"/images/felsen-gänge_1.jpg"} mt={10} mb={10} />
        <MyText repeats={2}>
          Almost looks like some kind of catacomb, right? Don't worry though, there should't be any
          skeletons lying around here.
        </MyText>
        <Space h={10} />
        <MyText repeats={6}>
          Now, what are these cellars and what were they used for? As Nuremberg lies in the heart of
          Bavaria, you have probably guessed it. The answer to this, and most other questions, is
          'beer'. However, you won't get more information here, as that would spoil this tour as
          much as any beer that has been forgotten in these cellars for hundreds of years.
        </MyText>
      </ModuleItem>
      <ModuleItem
        title={"20:00 Dinner at Eleon"}
        mapLink={
          "https://www.google.com/maps/place/eleon/@49.4556934,11.0613975,17z/data=!3m1!4b1!4m6!3m5!1s0x479f56521dd6ae69:0x159597a7f130d5bf!8m2!3d49.4556899!4d11.0639724!16s%2Fg%2F1hc1cdkr3?entry=ttu"
        }
        websiteLink={"https://www.eleon-online.de"}
      >
        <MyText repeats={5}>
          What would a PUFO be without food? So naturally, because all of you will have made it all
          the way up to Germany, the opportunity to serve you the perfect food must be seized. And
          what food is considered perfect in Germany? Exactly, the Greek cuisine!
        </MyText>
        <Space h={10} />
        <MyText repeats={3}>
          Get ready for Gyros, Tzatziki, Halloumi and all the other Greek delights that I definitely
          know about.
        </MyText>
      </ModuleItem>
    </ModuleCard>
  );
};
