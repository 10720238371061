import { Stack } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { isMobile } from "../../utils";
import { GeneralInformation } from "../landing-page/general-info";
import { Friday } from "./friday";
import { Saturday } from "./saturday";
import { Sunday } from "./sunday";
import { LanguageMenu } from "./language-menu";
import { useState, createContext } from "react";
import { Thursday } from "./thursday";

export const Itinerary = () => {
  const { width } = useViewportSize();
  const [language, setLanguage] = useState("English");

  return (
    <LanguageContext.Provider
      value={{
        language: language,
        setLanguage: setLanguage,
      }}
    >
      <Stack mb={40}>
        <LanguageMenu />
        <GeneralInformation isMobile={isMobile(width)} />
        <Thursday />
        <Friday />
        <Saturday />
        <Sunday />
      </Stack>
    </LanguageContext.Provider>
  );
};

type LanguageContextType = {
  language: string;
  setLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "",
  setLanguage: () => {},
});
