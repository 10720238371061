import { ModuleCard } from "../../components/module-card";
import { ModuleItem } from "../../components/module-item";
import { MyText } from "../../components/text";

export const Sunday = () => {
  return (
    <ModuleCard date="Sunday 10. September">
      <ModuleItem
        title={"Tour of Nuremberg by an experienced and (almost) certified tour guide"}
        mapLink={"https://www.google.com/maps/@-50.6076794,165.9759092,14.58z?entry=ttu"}
        websiteLink={"/told-you"}
      >
        <MyText repeats={7}>
          For those of you who are brave enough to stick around until Sunday, there will be a
          private tour of some of the highlights Nuremberg has to offer. The agenda of this tour
          will be a suprise, you can decide if you think this is because surprise activities are fun
          or because there is no agenda yet. You can believe what you want, but, if you're staying
          Sunday, you'll definitely want to see what your host has come up with!
        </MyText>
      </ModuleItem>
      <ModuleItem
        title={"20:00 Dinner at Hüttn Nuremberg"}
        mapLink={
          "https://www.google.com/maps/place/Wirtshaus+H%C3%BCtt'n/@49.4571527,11.0689781,15.5z/data=!4m6!3m5!1s0x479f57b01e868279:0xf1010e8250c86fb1!8m2!3d49.4570908!4d11.075194!16s%2Fg%2F1ts2z86l?entry=ttu"
        }
        websiteLink={"http://www.huettn-nuernberg.de/home/"}
      >
        <MyText repeats={4}>
          And to finish the weekend with a bang, you'll have some more German food! If you're happy
          about that, you are absolutely welcome! And if not? Then you should think twice about
          coming to Germany my friend. Anyway, dinner will be at the so-called Hüttn Nürnberg.
        </MyText>
      </ModuleItem>
    </ModuleCard>
  );
};
