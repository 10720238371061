import { useContext } from "react";
import { Title } from "@mantine/core";
import { LanguageContext } from "../views/itinerary/itinerary";

export const MyTitle = (props: { children: string; size?: number }) => {
  const context = useContext(LanguageContext);
  return (
    <Title size={props.size}>
      {context.language === "English" ? props.children : "Que te folle un pez polla."}
    </Title>
  );
};
