import { Text } from "@mantine/core";
import { ReactNode, useContext } from "react";
import { LanguageContext } from "../views/itinerary/itinerary";

export const MyText = (props: {
  children: ReactNode;
  ff?: string;
  fw?: string;
  color?: string;
  size?: string | number;
  repeats?: number;
  italic?: boolean;
}) => {
  const context = useContext(LanguageContext);
  return (
    <Text
      italic={props.italic}
      lh={2}
      ff={props.ff}
      fw={props.fw}
      color={props.color}
      size={props.size}
    >
      {context.language === "English" ? props.children : spanishInsultThing(props.repeats)}
    </Text>
  );
};

function spanishInsultThing(repeats?: number): string {
  let text = "Que te folle un pez polla.";
  if (!repeats) return text;
  for (let i = 0; i < repeats; i++) {
    text += " Que te folle un pez polla.";
  }
  return text;
}
