import { Center, Box, useMantineTheme, Title } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { ReactNode } from "react";
import { isMobile } from "../utils";

export const ModuleCard = (props: {
  children: ReactNode;
  date?: string;
  noPadding?: boolean;
  colour?: string;
}) => {
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  return (
    <Center w={"100%"} mt={30}>
      <Box
        w={isMobile(width) ? "95%" : "50%"}
        sx={{ backgroundColor: props.colour || theme.fn.lighten(theme.colors.green[8], 0.9) }}
      >
        <Box p={props.noPadding ? 0 : 20}>
          {props.date && (
            <Center mb={12} mt={10}>
              <Title size={22}>{props.date}</Title>
            </Center>
          )}
          {props.children}
        </Box>
      </Box>
    </Center>
  );
};
