import { Images } from "./types";

export function isMobile(width: number): boolean {
  return width <= 768;
}

export const importAll = (require: __WebpackModuleApi.RequireContext) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {} as Images);

export const durationInMs = (date1: Date, date2: Date): number => {
  return Math.abs(date1.getTime() - date2.getTime());
};

export const formatMilliseconds = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return `${days} days, ${hours % 24} hours, ${minutes % 6} minutes, ${seconds % 60} seconds`;
};
