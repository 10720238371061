import { Group } from "@mantine/core";
import { CountdownData } from "../../types";
import { CountDownBox } from "../../components/countdown-box";

export const Countdown = (props: { timeToDeadline: CountdownData; isMobile: boolean }) => {
  return (
    <Group>
      {Object.entries(props.timeToDeadline).map(([key, value], index) => {
        return (
          <CountDownBox
            key={index}
            number={value}
            delimiter={value === 1 ? key.slice(0, -1) : key}
            isMobile={props.isMobile}
          />
        );
      })}
    </Group>
  );
};
