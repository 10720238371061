import { Center } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

export const ToldYou = () => {
  const { width, height } = useViewportSize();
  return (
    <Center w={width} h={height}>
      I told you the agenda of the tour is secret.{" "}
    </Center>
  );
};
