import { TripInfo } from "../../types";

export const tripsData: TripInfo[] = [
  {
    who: ["Helen", "Christian"],
    airport: "/",
    arrival: "Thursday",
    departure: "Monday",
  },
  {
    who: ["Matu", "David", "Rafe"],
    airport: "NUE",
    arrival: "17:40 Thursday",
    departure: "14:30 Monday",
  },
  {
    who: ["Luz", "Mariajose", "Jaca", "Havier"],
    airport: "MUC",
    arrival: "19:15 Thursday",
    departure: "12:15 Sunday",
  },
  {
    who: ["Mamen", "Rosario", "Juanma", "Pepe"],
    airport: "NUE",
    arrival: "13:15 Friday",
    departure: "14:55 Sunday",
  },
];
