import { Group, Anchor } from "@mantine/core";
import { IconBrandGoogleMaps, IconExternalLink } from "@tabler/icons-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const LinkWithIcon = (props: { icon: ReactNode; label: string; link: string }) => (
  <Group spacing={4}>
    {props.icon}
    <Anchor href={props.link} target="_blank">
      {props.label}
    </Anchor>
  </Group>
);

export const LinkGroup = (props: { mapsLink: string; websiteLink: string }) => {
  return (
    <Group spacing={10}>
      {props.websiteLink[0] === "/" ? (
        <Group spacing={4}>
          <IconExternalLink size={16} />
          <Link to={"/told-you"}>
            <Anchor href={""} target="_blank">
              Website
            </Anchor>
          </Link>
        </Group>
      ) : (
        <LinkWithIcon
          icon={<IconExternalLink size={16} />}
          label="Website"
          link={props.websiteLink}
        />
      )}
      <LinkWithIcon icon={<IconBrandGoogleMaps size={16} />} label="Maps" link={props.mapsLink} />
    </Group>
  );
};

export const MapsLink = (props: { link: string }) => {
  return <LinkWithIcon icon={<IconBrandGoogleMaps size={16} />} label="Maps" link={props.link} />;
};

export const WebsiteLink = (props: { link: string }) => {
  return <LinkWithIcon icon={<IconExternalLink size={16} />} label="Website" link={props.link} />;
};
