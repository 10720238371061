import { Box, Grid, Group, List, Space, Stack, Table } from "@mantine/core";
import { ModuleCard } from "../../components/module-card";
import { LinkGroup } from "../../components/link-with-icon";
import { tripsData } from "../itinerary/arrival-data";
import { MyText } from "../../components/text";
import { MyTitle } from "../../components/title";

export const GeneralInformation = (props: { isMobile: boolean }) => {
  return (
    <ModuleCard noPadding={true}>
      <Box
        sx={{
          backgroundImage: `url("/images/group_2.jpg")`,
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: props.isMobile ? "250px" : "500px",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            position: "absolute",
            bottom: props.isMobile ? "-20px" : "50px",
            left: props.isMobile ? "15px" : "40px",
            alignContent: "center",
            display: "flex",
            padding: props.isMobile ? "10px" : "15px",
          }}
        >
          <Stack spacing={0}>
            <MyText ff={"fantasy"} fw={"bold"} color={"dark"} size={20}>
              The itinerary of PUFO 2023
            </MyText>
            <MyText ff={"fantasy"} color={"dark"} size={"xs"}>
              Brought to you by Helen and Christian
            </MyText>
          </Stack>
        </Box>
      </Box>
      <Box p={20}>
        <Space h={26} />
        <MyTitle size={20}>General Information</MyTitle>
        <Grid mt={6}>
          <Grid.Col>
            <List spacing={6}>
              <List.Item>
                <Group>
                  <MyText>Hotel: "the niu Leo"</MyText>
                  <LinkGroup
                    mapsLink="https://www.google.com/maps/place/the+niu+Leo/@49.4426223,11.0536724,17z/data=!3m1!4b1!4m9!3m8!1s0x479f571f2b51517f:0x469c4c182708fcbc!5m2!4m1!1i2!8m2!3d49.4426223!4d11.0562473!16s%2Fg%2F11gy1wj25n?entry=ttu"
                    websiteLink="https://the.niu.de/en/hotels/germany/nuremberg/the-niu-leo"
                  />
                </Group>
              </List.Item>
              <List.Item>
                <MyText>Official start: Friday 8. September</MyText>
              </List.Item>
              <List.Item>
                <MyText>
                  You can also arrive on Thursday and enjoy a relaxing and activity-free evening
                  with the host
                </MyText>
              </List.Item>
            </List>
          </Grid.Col>
          <Grid.Col>
            <Space h={10} />
            <MyTitle size={16}>When is everybody going to arrive?</MyTitle>
            <Table sx={{ backgroundColor: "white" }} withColumnBorders mt={20}>
              <thead>
                <tr>
                  <th>Who</th>
                  <th>Arrival</th>
                  <th>Departure</th>
                  <th>Airport</th>
                </tr>
              </thead>
              <tbody>
                {tripsData.map(data => {
                  return (
                    <tr key={data.who[0]}>
                      <td>{data.who.join(", ")}</td>
                      <td>{data.arrival}</td>
                      <td>{data.departure}</td>
                      <td>{data.airport}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Grid.Col>
        </Grid>
      </Box>
    </ModuleCard>
  );
};
