import { Group, Avatar, Button } from "@mantine/core";
import { useContext } from "react";
import { LanguageContext } from "../views/itinerary/itinerary";

export const LanguageButton = (props: { language: string; flagSrc: string }) => {
  const context = useContext(LanguageContext);
  return (
    <Button
      onClick={() => {
        context.setLanguage(props.language);
        console.log("New language " + props.language);
      }}
      variant="underline"
    >
      <Group>
        {props.language}
        <Avatar src={props.flagSrc} radius={"xl"} size={"sm"} />
      </Group>
    </Button>
  );
};
