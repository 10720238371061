import { Image, Text, Stack, Space, Button } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { durationInMs, isMobile } from "../../utils";
import { useEffect, useState } from "react";
import { CountdownData } from "../../types";
import { Countdown } from "./countdown";
import { Link } from "react-router-dom";

export const Welcome = () => {
  const { height, width } = useViewportSize();
  const deadline = new Date("2023-09-01T12:00:00");
  const [timeToDeadline, setTimeToDeadline] = useState(getTimeToDeadline(deadline));

  const released = deadline.getTime() < new Date().getTime();
  const mobile = isMobile(width);

  useEffect(() => {
    if (!released) {
      setInterval(() => setTimeToDeadline(getTimeToDeadline(deadline)), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [released]);

  return (
    <Stack
      sx={{ width: "100%", height: height + "px", backgroundColor: "black" }}
      align="center"
      spacing={0}
    >
      <div style={{ justifyContent: "end", display: "flex", width: "100%" }}>
        <Link to={"/itinerary"} style={{ textDecoration: "none" }}>
          <Text color="black">admin</Text>
        </Link>
      </div>
      <Space h={"1%"} />
      <Image src={"pufo-logo-laurels-black.png"} style={{ width: "80%", maxWidth: "400px" }} />
      <Space h={30} />
      <Text color="white" size={25} fw={"bold"} align="center">
        Release of the itinerary for PUFO 2023 in Nuremberg in:
      </Text>
      <Space h={20} />
      <Countdown timeToDeadline={timeToDeadline} isMobile={mobile} />
      <Space h={20} />
      {released ? (
        <Release isMobile={mobile} />
      ) : (
        <Text color="white" size={16} fw={"bold"} align="center" sx={{ backgroundColor: "black" }}>
          And remember "Que te folle un pez polla!"
        </Text>
      )}
    </Stack>
  );
};

const Release = (props: { isMobile: boolean }) => {
  return (
    <>
      <Text sx={{ color: "white" }} size={"lg"} align="center">
        Germany is ready for PUFO once again . . . {props.isMobile ? <br /> : <>&nbsp;</>} Are you?
      </Text>
      <Space h={18} />
      <Link to={"/itinerary"}>
        <Button color={"purpleish"}>Tell me more</Button>
      </Link>
    </>
  );
};

const getTimeToDeadline = (deadline: Date): CountdownData => {
  if (deadline.getTime() < new Date().getTime()) {
    return {
      Days: 0,
      Hours: 0,
      Minutes: 0,
      Seconds: 0,
    };
  }

  const duration = durationInMs(deadline, new Date());
  return {
    Days: Math.floor(duration / (1000 * 60 * 60 * 24)),
    Hours: Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    Minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
    Seconds: Math.floor((duration % (1000 * 60)) / 1000),
  };
};
