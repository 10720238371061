import { Box, Space } from "@mantine/core";
import { LinkGroup } from "./link-with-icon";
import { ReactNode } from "react";
import { MyTitle } from "./title";

export const ModuleItem = (props: {
  title: string;
  mapLink: string;
  websiteLink: string;
  children: ReactNode;
}) => {
  return (
    <Box mt={35}>
      <MyTitle size={18}>{props.title}</MyTitle>
      <Box ml={14} mt={6} mb={20} mr={14}>
        {props.children}
        <Space h={10} />
        <LinkGroup mapsLink={props.mapLink} websiteLink={props.websiteLink} />
      </Box>
    </Box>
  );
};
