import { Group } from "@mantine/core";
import { LanguageButton } from "../../components/language-button";

export const LanguageMenu = () => {
  return (
    <div style={{ height: "20px", width: "100%", justifyContent: "end", display: "flex" }}>
      <Group mt={4} spacing={2}>
        <LanguageButton language={"English"} flagSrc={"/images/english_flag.png"} />
        <LanguageButton language={"Spanish"} flagSrc={"/images/spanish_flag.png"} />
      </Group>
    </div>
  );
};
