import { Center, Text } from "@mantine/core";

export const CountDownBox = (props: { number: number; delimiter: string; isMobile: boolean }) => {
  return (
    <Center
      sx={{
        backgroundColor: "#413c69",
        fontWeight: 700,
        color: "#DCD6F7",
        borderRadius: "15px",
        width: props.isMobile ? "60px" : "100px",
        height: props.isMobile ? "60px" : "100px",
        margin: props.isMobile ? "2px" : "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text size={props.isMobile ? 20 : 30}>{props.number}</Text>
      <Text size={props.isMobile ? 10 : 20}>{props.delimiter}</Text>
    </Center>
  );
};
