import { Image } from "@mantine/core";
import { ModuleCard } from "../../components/module-card";
import { ModuleItem } from "../../components/module-item";
import { MyText } from "../../components/text";

export const Saturday = () => {
  return (
    <ModuleCard date="Saturday 9. September">
      <ModuleItem
        title="10:30 Tour of the Nazi rally grounds"
        mapLink={
          "https://www.google.com/maps/place/Zeppelinfeld/@49.4308743,11.1216273,15z/data=!4m6!3m5!1s0x479f59cf8f301e23:0x6822e36a25f4bccc!8m2!3d49.4317147!4d11.1249948!16s%2Fg%2F1vk6y6h5?hl=de&entry=ttu"
        }
        websiteLink={"https://en.wikipedia.org/wiki/Nazi_party_rally_grounds"}
      >
        <MyText repeats={7}>
          Imagine this: You wake up Saturday morning, you're probably not feeling too great and have
          a hangover somewhere in between 'This is mildly annoying' and 'My head is going to
          explode'. After a hearty breakfast the best way to combat the hangover are some sobering
          thoughts. And those can be found aplenty on a tour about old nazi Germany and the former
          nazi rally grounds. Get ready to dive headfirst into Nurembergs darker history.
        </MyText>
        <Image src={"/images/nazi-rally-grounds.jpg"} mt={16} />
      </ModuleItem>
      <ModuleItem
        title={"17:00 Nuremberg Volksfest"}
        mapLink={
          "https://www.google.com/maps/place/eleon/@49.4556934,11.0613975,17z/data=!3m1!4b1!4m6!3m5!1s0x479f56521dd6ae69:0x159597a7f130d5bf!8m2!3d49.4556899!4d11.0639724!16s%2Fg%2F1hc1cdkr3?entry=ttu"
        }
        websiteLink={"https://www.gigerlas-loessel.de/25-08-2023-nuernberger-herbstvolksfest/"}
      >
        <MyText repeats={6}>
          This next point on the agenda is what you all have been hoping and waiting for. Because
          what would a visit to Germany be without going to a Volksfest? Correct, an absolute waste
          of time! Get ready to drink beer by the liter, eat classic German food and dance on the
          tables while singing stupid 'Schlager' songs.
        </MyText>
      </ModuleItem>
    </ModuleCard>
  );
};
