import { createBrowserRouter } from "react-router-dom";
import { Welcome } from "./views/landing-page/welcome";
import { Itinerary } from "./views/itinerary/itinerary";
import { ToldYou } from "./views/itinerary/told-you";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Welcome />,
    children: [
      {
        path: "",
        element: <Welcome />,
      },
      {
        path: "itinerary",
        element: <Itinerary />,
      },
      {
        path: "told-you",
        element: <ToldYou />,
      },
    ],
  },
]);
