import { useState } from "react";
import { ModuleCard } from "../../components/module-card";
import { Anchor } from "@mantine/core";
import { MyText } from "../../components/text";
import { ModuleItem } from "../../components/module-item";

export const Thursday = () => {
  const [opened, setOpened] = useState(false);
  return (
    <ModuleCard date={"Thursday 7. September"}>
      {opened ? (
        <>
          <ModuleItem
            title={"22:00 Dinner at Palais Schaumburg"}
            mapLink={
              "https://www.google.com/maps/place/Palais+Schaumburg/@49.4490753,11.049439,13.81z/data=!4m6!3m5!1s0x479f56f8a595f6b9:0xe404c94d94703ee3!8m2!3d49.4482269!4d11.0535621!16s%2Fg%2F1tmxdkb4?entry=ttu"
            }
            websiteLink={"https://www.palaisschaumburg.de/"}
          >
            <MyText repeats={5}>
              To revitalise the poor souls that only arrive this late, a nice dinner is planned at
              Palais Schaumburg. You'll start the PUFO weekend with some nice traditional German
              food in a place with a funny and weird name. I hope you all appreciate that it is not
              easy to find a restaurant in Germany that serves food this late!
            </MyText>
          </ModuleItem>
          <Anchor onClick={() => setOpened(false)}>Hide</Anchor>
        </>
      ) : (
        <>
          <MyText>For everyone arriving on Thursday: </MyText>
          <Anchor onClick={() => setOpened(true)}>Show more...</Anchor>
        </>
      )}
    </ModuleCard>
  );
};
